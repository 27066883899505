exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-blog-list-template-js": () => import("./../../../src/templates/blog-list-template.js" /* webpackChunkName: "component---src-templates-blog-list-template-js" */),
  "component---src-templates-blog-template-js": () => import("./../../../src/templates/blog-template.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-templates-build-template-js": () => import("./../../../src/templates/build-template.js" /* webpackChunkName: "component---src-templates-build-template-js" */),
  "component---src-templates-builder-template-js": () => import("./../../../src/templates/builder-template.js" /* webpackChunkName: "component---src-templates-builder-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-storage-type-template-js": () => import("./../../../src/templates/storage-type-template.js" /* webpackChunkName: "component---src-templates-storage-type-template-js" */)
}

